import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, Typography } from '@material-ui/core';
import { SideBar } from '../../components/SideBar'
import { useStyles } from './styles'
import { Link, useHistory } from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Cookies from 'js-cookie';
import { signOut } from '../../helpers';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MarqueeText, AlertBannerText } from '../../components';
import { getEmergencyAlert, feedbackDisplay } from '../../actions';
import { LogoutConfirmation } from '../LogoutConfirmation/LogoutConfirmation';
import { getPointPrivateLabel } from '../../helpers';
import signin from '../../assets/signin.png';
import createAccount from '../../assets/createAccount.png';
import { LogoutSurvey } from '../LogoutSurvey';

export const Header = () => {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.theme);
  const emergencyAlert = useSelector(state => state.emergencyAlertReducer);
  const loanInfoReducer = useSelector(state => state?.loanInfoReducer);

  const history = useHistory();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [login, setLogin] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [maintenanceMessage, setMaintenanceMessage] = React.useState(theme.maintenanceMessage && !getPointPrivateLabel() ? theme.maintenanceMessage : null);
  const [logoutStatus, setLogoutStatus] = React.useState(false);
  const [displaySurvey, setDisplaySurvey] = React.useState(false);
  const [surveyText, setSurveyText] = React.useState('');

  const closeAlertBanner = () => {
    setAlertMessage(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
 
  const emergencyAlertContent = async () => {
    if ((emergencyAlert == null) && !getPointPrivateLabel()) {
      const response = await dispatch(getEmergencyAlert())
      if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') { 
      const data = response.responseData.responseData;   
      setAlertMessage(data)
      }
    } else {
      setAlertMessage(emergencyAlert)
    }
  }

  React.useEffect(() => {
    emergencyAlertContent()
    if (Cookies.get('privateToken')) {
      setLogin(true)
    }
  }, []);

  const handleLogOut = () => {
    setLogin(false)
    signOut(() => {
      const privatelabel = window.location.pathname.split('/')[1];
      if (privatelabel == 'bsi' && Cookies.get('clientName') != 'VRM') {
         Cookies.remove('clientName');
         history.push({pathname : '/SignIn', state: { fromLogout: true }})
         window.location.reload(false)
       } else {
        Cookies.remove('clientName');
        history.push({pathname : '/SignIn', state: { fromLogout: true }})
        window.location.reload(false)
       }
    })
    handleClose()
  }
 
  const handleCancelIcon = () => {
    setLogoutStatus(false)
  }
 
  const handleSubmitClose = () => {
    setLogoutStatus(false)
  }

  const handleSubmit = () => {
    setLogoutStatus(false)
    handleLogOut()
  }

  const handleLogOutConfirmation = () => {
    if (!loanInfoReducer.isLandingFeedbackDisplay) {
      setLogoutStatus(true)
    } else {
      fetchFeedbackDisplay()
    }
  } 
  
  const fetchFeedbackDisplay = async () => {
    const result = await feedbackDisplay(loan, "LandingFeedback");
    if (result) {
      if (result?.responseData?.lstResponseStatus[0].statusCode == "0") {
        setSurveyText(result?.responseData?.responseData?.headerText)
        setDisplaySurvey(true)
      }
    }
  }

  const { privateLabelLogoURL, privateLabelName } = theme;

  return (
    <div className={`${(maintenanceMessage !== null && alertMessage !== null) ?  classes.root1 : classes.root} ${(maintenanceMessage !== '' && maintenanceMessage !== null && alertMessage == null) ? classes.marqueeRoot :(maintenanceMessage !== null && alertMessage !== null) ? classes.marqueeRoot1 : (maintenanceMessage == null && alertMessage !== null) ? classes.marqueeRoot2 :  classes.marqueeNotAvl}`}>

      {(!getPointPrivateLabel() && maintenanceMessage !== '' && maintenanceMessage !== null) && <MarqueeText text={maintenanceMessage} alertMessage={alertMessage}/>}
      {(!getPointPrivateLabel() && alertMessage !== '' && alertMessage !== null) && <AlertBannerText text={alertMessage} 
      closeAlertBanner={closeAlertBanner}/>}

      <AppBar position="fixed" className={`${classes.appBar} ${ (maintenanceMessage !== null && alertMessage == null) ? classes.marqueeAppBar  : (maintenanceMessage !== null && alertMessage !== null) ? classes.marqueeAppBar3 :  (maintenanceMessage == null && alertMessage !== null) ? classes.marqueeAppBar2  :classes.marqueeNotAvl}`} >
        <Toolbar>
          <IconButton 
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Link to={login ? '/Dashboard' : '/'} className={classes.logoContainer}>
            <img className={classes.logoStyle} src={privateLabelLogoURL} alt={privateLabelName} />
          </Link>

          <Box className={classes.grow} />
          <nav className={classes.linkContainer}>
            {/* <Link className={classes.linkStyle} to='/AboutUs'>ABOUT US</Link> */}
            <Link className={classes.linkStyle} to='/ThirdPartyRequest'>THIRD PARTY REQUESTER</Link>
            {!getPointPrivateLabel() && <Link className={classes.linkStyle} to='/HelpDescription/FAQs'>FAQs</Link>}
          </nav>
          {login ?
            <button
              onClick={() => handleLogOutConfirmation()}
              className={classes.signInBtn}>Sign Out</button>
            :
            <Link className={classes.linkText} to="/SignIn" >
              <button className={classes.signInBtn} >
                Sign In
              </button>
            </Link>
          }
           <Link className={classes.linkText} to="/AccountVerification" >
              <button className={classes.createAccBtn} >
                Create Account
              </button>
            </Link>
          {login ?
            <Box >
              <IconButton className={classes.profileIcon} aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
                <AccountCircle />
              </IconButton>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ disablePadding: true }}
              >
                <MenuItem onClick={handleClose} className={classes.menuItemStyle}>My Profile</MenuItem>
                <MenuItem onClick={handleClose} className={classes.menuItemStyle}>Communication Preferences</MenuItem>
                <MenuItem onClick={handleClose} className={classes.menuItemStyle}>Reset Password</MenuItem>
                <MenuItem onClick={handleLogOutConfirmation} className={classes.menuItemStyle}>Sign Out</MenuItem>
              </Menu>
            </Box>
            :
            <Box className={classes.mobileCheck}>
              <Link className={classes.linkText} to="/AccountVerification" >
                {/* <IconButton className={classes.profileIcon} aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
                  <AccountCircle />
                </IconButton> */}
                <img className={classes.profileIcon} src={createAccount} style={{width: 24, height: 24, margin: 'auto'}}/>
                <Box className={classes.signIn}>Sign up</Box>
              </Link>
              <Link className={classes.linkText} to="/SignIn" >
              <img className={classes.profileIcon} src={signin} style={{width: 24, height: 24, margin: 'auto'}} />
                {/* <IconButton className={classes.profileIcon} aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
                  <AccountCircle />
                </IconButton> */}
                <Box className={classes.signIn}>Sign in</Box>
              </Link>
            </Box>
          }
        </Toolbar>
      </AppBar>
      <SideBar
        login={login}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      { logoutStatus &&
        <LogoutConfirmation
         open={logoutStatus}
         handleCancelIcon={handleCancelIcon}
         handleSubmitClose={handleSubmitClose}
         handleSubmit={handleSubmit}
       />
      }
      
       <LogoutSurvey
         open={displaySurvey}
         handleCancelIcon={handleCancelIcon}
         handleSubmitClose={handleSubmitClose}
         handleSubmit={handleSubmit}
         surveyText={surveyText}
       />
    </div>
  );
}



