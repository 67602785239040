import React, {useState} from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography, TextareaAutosize } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { updateMyFeedbackProtected, insertActivityLog } from '../../actions';
import { getAppSource, getUserType, isAdminCheck } from '../../helpers';
import Cookies from 'js-cookie';

export const LogoutSurvey = (props) => {
  
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [documentDesc, setDocumentDesc] = useState("");
  const [rating, setRating] = useState();
  const [ratings, setRatings] = useState([{text: 0, label: 'Extremely Unlikely'}, {text: 1, label: ''}, {text: 2, label: ''}, {text: 3, label: ''}, {text: 4, label: ''}, {text: 5, label: ''}, {text: 6, label: ''}, {text: 7, label: ''}, {text: 8, label: ''}, {text: 9, label: ''}, {text: 10, label: 'Extremely Likely'}])
  
  const handleRequestDescChange = (event) => {
    setDocumentDesc(event.target.value)
  }
  
  const getTextColor = (index) => {
    if ((rating < 7) && (rating >= index)) {
      return '#FFFFFF'
    } else if ((rating < 9) && (rating >= index)) {
     return '#FFFFFF'
    } else if ((rating < 11) && (rating >= index)) {
      return '#FFFFFF'
    }
  }

  const getBgColor = (index) => {
    if ((rating < 7) && (rating >= index)) {
      return '#E02020'
    } else if ((rating < 9) && (rating >= index)) {
     return '#F7B15C'
    } else if ((rating < 11) && (rating >= index)) {
      return '#198000'
    }
  }

  const getBorderColor = (index) => {
   if ((rating != undefined) && (index <= rating)) {
       return '1px solid #FFFFFF'
    } else if (index < 7) {
      return '1px solid #E02020'
    } else if (index < 9) {
      return '1px solid #F7B15C'
    } else {
      return '1px solid #198000'
    }
  };
 
  const handleSubmitSurvey = async () => {
    const data = {
      "loanNumber": Cookies.get("selectedAccount"),
      "userName": "",
      "feedbackRating": rating,
      "feedbackComment": documentDesc,
      "feedbackType": "LandingFeedback"
    }

    const result = await updateMyFeedbackProtected(data);
    props.handleSubmit()
  }

  const surveySubmit = () => {
    if (isAdminCheck()) {
      props.handleSubmit()
    } else {
      handleSubmitSurvey()
    }
  }

  const sendActivityLog = async () => {
    const data = {
      "accountNumber": Cookies.get("selectedAccount"),
      "activityCode": "UAL206",
      "activityStatus": "Success",
      "action": "",
      "controller": "Feedback",
      "apiName": "Feedback",
      "activityRemark": "MLW_Lending Survey-Borrower clicked on skip button to skip on sign out popup",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
    props.handleSubmit()
  }

  const handleSkip = () => {
    if (isAdminCheck()) {
      props.handleSubmit()
    } else {
      sendActivityLog()
    }
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleClose} open={props.open}>
    
    <Box className={classes.root}> 
      <Box className={classes.cardBody}>
        <Typography className={classes.statusTitle}>BSI Lending Consideration Feedback</Typography>
      </Box>
      
      <Box className={classes.cardBody2}>
         <Box className={classes.cardContainer}>
          <Box className={classes.imageContainer}>
           <Typography className={classes.paragraph}>{props.surveyText}</Typography>

            {ratings.map((item, index) => <>
                <Box className={classes.rating} style={{border: getBorderColor(index), backgroundColor: getBgColor(index)}} key={index} onClick={() => setRating(item.text)}>
                    <span key={index} className={classes.ratingText} style={{color: getTextColor(index)}} >{item.text}</span>
                </Box>
              </>
            )}
            <span className={classes.sliderLabel}>
              <span className={classes.sliderText}>Extremely Unlikely</span>
              <span className={classes.sliderText}>Extremely Likely</span>
            </span>

           {(rating !== undefined) && <Box className={classes.textareaSection}>
              <TextareaAutosize
                style={{ height: '65px' }}
                color="primary"
                variant="outlined"
                type="text"
                name="comments"
                maxLength="500"
                onChange={(event) => handleRequestDescChange(event)}
                value={documentDesc}
                placeholder='Tell us more about your rating (Optional)...'
                className={classes.textArea}
              >
              </TextareaAutosize>
            </Box> }
          </Box> 
        </Box>
      </Box>

      <Box className={classes.cardFooter}>
       <Box className={classes.footerItem}>
          <Button
            variant='outlined'
            className={classes.btn}
            onClick={handleSkip}
          > SKIP & SIGN OUT</Button>
        </Box>

        <Box className={classes.footerItem}>
            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              disabled={rating == undefined}
              onClick={surveySubmit}
            >SUBMIT & SIGN OUT</Button>
        </Box> 
      </Box>
     
    </Box> 
  </Dialog>
  )
}
