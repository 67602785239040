import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { decrypt } from '../../helpers/encrypt';
import { getBorrowerLabel, getLoanLabel, maskAccount } from '../../helpers';
import checkCircle from '../../assets/checkCircle.svg';
import clsx from 'clsx';


export const ThirdPartyAlert = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { open, handleClose, data, submittedRequestType, thirdPartyEmailID } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        disableBackdropClick
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        className={classes.dialogClass}
      >
        <div className={classes.root}>
          <CancelIcon className={classes.closeBtn} onClick={handleClose} />
          <div className={classes.container}>
            <h2 className={classes.statusSuccess}>Your request has been submitted</h2>

            {(data === 'isVerifiedFalse') &&
              <div className={classes.content}>
                <p className={clsx(classes.text, classes.item)}>It looks like you are not a Pre-Authorized third party.
                The {getBorrowerLabel()?.toLowerCase()} has been notified to authorize your request.</p>
                <p className={clsx(classes.text, classes.item)}>You may have to wait for a few days for the {getBorrowerLabel()?.toLowerCase()} to approve your request.</p>
              </div>}
            {(data === 'isVerifiedTrue') &&
              <div className={classes.content}>
                {(submittedRequestType === 'Verification of Mortgage') ?
                  <p className={clsx(classes.text, classes.item)}>
                    It is not our policy to disclose information such as credit ratings, comments regarding credit worthiness or
                    a complete payment history of the account. Based on your {getLoanLabel()?.toLowerCase()} status and availability of documents,
                    it may take 1-2 business days to process your request. If you have any questions, please call or
                    fax our customer care center.
              </p> : (submittedRequestType === 'Payoff') ? <p className={clsx(classes.text, classes.item)}>
                    It is not our policy to disclose information such as credit ratings, comments regarding credit worthiness or
                    a complete payment history of the account. Based on your {getLoanLabel()?.toLowerCase()} status and availability of documents,
                    it may take 3-5 business days to process your request. If you have any questions, please call or
                    fax our customer care center.
              </p> : (submittedRequestType === 'subordination request') ? <p className={clsx(classes.text, classes.item)}>
                Your request has been submitted
                Please allow 1-2 Business days for your request to be complete. You will receive your request to the email address you have provided.
                <p className={clsx(classes.text, classes.item)}>Email: <a href={'mailto:'+thirdPartyEmailID}>{thirdPartyEmailID} </a></p>
                
              </p> :
               <p className={clsx(classes.text, classes.item)}>
                      It is not our policy to disclose information such as credit ratings, comments regarding credit worthiness or
                      a complete payment history of the account. Based on your {getLoanLabel()?.toLowerCase()} status and availability of documents,
                      it may take 1-2 business days to process your request. If you have any questions, please call or
                      fax our customer care center.
              </p>
              
            }
                <h4 className={`${classes.statusSuccess} ${classes.thaanks}`}>Thank You</h4>
              </div>}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
