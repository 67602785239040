import { axiosProtected,axiosPublic } from '../config';
import Cookies from 'js-cookie';
import { getAppSource, getUserType } from '../helpers'

  export const updateMyFeedbackProtected = async (payload) => {
    try {

        const data = {
            "privateLabelUrl": Cookies.get("privateLabelUrl"),
            "ipAddress": Cookies.get('publicIp'),
            "browser": Cookies.get("browser"),
            "appSource": getAppSource(),
            "userType": getUserType(),
            ...payload
          }

      const res = await axiosProtected.post('Feedback/SubmitBorrowersFeedback', data);
      return res.data
    } catch (e) {    
      return null
    }
  }

  export const feedbackDisplay = async (loanNumber, type) => {
    try {

        const data = {
            "privateLabelUrl": Cookies.get("privateLabelUrl"),
            "ipAddress": Cookies.get('publicIp'),
            "browser": Cookies.get("browser"),
            "appSource": getAppSource(),
            "userType": getUserType(),
            "feedbackType": type,
            "userName": "",
            "accountNumber": loanNumber
          }

      const res = await axiosProtected.post('Feedback/Display', data);
      return res.data
    } catch (e) {    
      return null
    }
  }