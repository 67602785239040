export const PAPERLESS_TOOLTIP='By switching to e-billing, you take another important step to shrink your environmental footprint.'
export const EMAIL_TOOLTIP='Get notified quickly on profile changes, payment confirmations and to receive e-statements.'
export const PHONE_TOOLTIP="We'll use your phone number to call you regarding important account activity."
export const MESSAGE_TOOLTIP="We'll use your phone number to text you regarding important account activity."
export const INFO_ADMIN_RECORD_TWO="The selection reflects the result set to display a list of active and inactive users."
export const INFO_ADMIN_RECORD="The selection reflects the result set to display activity log with technical details."
export const HOW_IT_WORKS_PROPERTY_TAX =  "Property taxes are paid by an individual or entity on owned property, based on your home’s value and the relevant property tax rates. If your account is escrowed for taxes, we obtain the information on what is due from your local taxing authority and pay these taxes on your behalf from your escrow account, with certain limited exceptions."
export const HOW_IT_WORKS_MORTGAGE_TAX = "Mortgage insurance, also referred to as MIP or PMI, allows you to get a mortgage with a down payment that is lower than the typical 20%. This is insurance that protects the lender’s interest in the event of a default on your loan. Most mortgage insurance premiums are paid monthly."
export const CIT_MESSAGE_LIMIT = 368;
export const ESCROW = "Your loan is escrowed for this coverage, and these payments come out of your escrow account."
export const ADD_ESCROW_TEXT1 = "You are requesting to add this line item to your escrow account."
export const ADD_ESCROW_TEXT2 = "Please note that you will need to upload necessary required documents to complete this request, as well as check the acknowledgement box and click “Submit” for your request to be submitted for review."
export const REMOVE_ESCROW_TEXT1 = "You are requesting to remove this line item from your escrow account."
export const REMOVE_ESCROW_TEXT2 = "Please note that you will need to check the acknowledgement box below and click “Submit” for your request to be submitted for review."
export const I_AGREE = "Please note that this is ONLY a request for your escrow account to be reviewed for the requested changes. Once the review is complete, you will be notified of the results of our review."
export const FILE_SIZE_LIMII = 2097152;
export const FILE_LENGTH = 100;
export const AMOUNT_DIRECTLY_PAID = "This is not an item your loan is escrowed for. This amount must be paid directly by you to your tax authority."
export const SPECIAL_CHAR_NOT_ALLOWED = "A filename can’t contain any of the following characters:  \ / : * ? “ < > |"
export const FILE_LENGTH_LIMIT = "The specified filename is too long. The file name must be less than 100 characters."
export const SUBMIT_TEXT1 = "Your request has been successfully submitted."
export const SUBMIT_TEXT2 = 'Request identification numbers can be viewed on the "Taxes and Insurance information" page. We will endeavour to get back to you for any addititional information or with the decision on your request within 30 days.'    
export const ACCOUNT_TOOLTIP = 'Unfortunately this investment contract does not offer pay downs on this loan. Please refer to the Loan Note to determine how to pay this loan or contact customer care at 866-581-4514.'
export const MULTILOAN_ACCOUNT_TOOLTIP = "This investment contract does not offer paydowns. Please, refer to Loan Note."
export const OKTA_ISSUER = 'https://bsi.okta.com';
export const OKTA_CLIENTID = '0oa121yufbyjN25IG0x8';
export const TRANSFER_ALERT_POPUP_HEADING = 'NOTICE OF WEBSITE AND PHONE NUMBER UPDATE';
export const TRANSFER_ALERT_POPUP_SUB_HEADING = 'WHAT THIS MEANS FOR YOU';  
export const TRANSFER_ALERT_POPUP_SUB_SUB_HEADING = 'Please note that there has been no change to your account, or where you send your payments monthly. Listed below is your new website and contact information.';
export const PAPERLESS_CONSENT = 'Paperless options reduces environmental harm and outlays. Why print when a single click can save the planet?';
export const SNACKBAR_SUCCESS = 'Changes saved successfully!';
export const ESCROW_PAYMENT = 'Borrower is responsible for all escrow payments on this loan';
export const BENEFIT_OF_PAYING_MORE = 'Paying extra towards your principal balance will help you save on interest and pay your loan off sooner. Your interest payment is calculated on your remaining principal balance. Therefore, as you pay more towards your principal balance, the amount you pay in interest can decrease significantly. Ultimately, paying more towards principal, saves you interest and will help pay your loan off sooner.';
export const PROCESSING_FEE = 'Processing fee of $7.00 is applied for payments overdue by more than 5 days.';

// export const OKTA_ISSUER = 'https://dev-95067802.okta.com/oauth2/default';
// export const OKTA_CLIENTID = '0oa7f5acv9jkNZAaX5d7';
export const SAVE_TIME_POPUP_HEADING = 'Save Time with Prefill';
export const BASIC_INFO_POPUP_SUB_HEADING = 'Would you like us to prefill some of your basic information?'; 
export const DISCLAIMER = 'Disclaimer: By clicking PROCEED & PREFILL button, you are authorizing BSI to share your basic information with "Covered" that includes your full name, property address, date of birth & contact details.';
export const REFINANCE_URL = 'https://bsifinancialrefinanceonline.mymortgage-online.com/';
