import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';


export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#ddd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain'
  },

  wrapper: {
    maxWidth: '57.8em',
    padding: '3.9em 4.9em',
    marginTop: '4.5em',
    marginBottom: '4.5em',
    backgroundColor: '#fff',
    borderRadius: 12,
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '20px',
      margin: '50px 15px'
    },
  },

  title: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '3.6em',
    fontWeight: "600",
    letterSpacing: "0.5px",
    marginBottom: 33,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.6em',
    },
  },


  hintText: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    letterSpacing: '0.4px',
    marginBottom: 23,
    marginTop: 0,
    marginLeft: 30
  },

  radioBtn: {
    fontSize: '1.6em'
  },

  resendBtnText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '0.9em',
    fontWeight: '600',
    textDecoration: 'underline',
    border: 'none',
    backgroundColor: '#fff',
    outline: 'none',
    cursor: "pointer",
  },

  proceedBtnText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '0.7em',
    fontWeight: '600',
    textDecoration: 'underline',
    border: 'none',
    backgroundColor: '#fff',
    outline: 'none',
    cursor: "pointer",
  },

  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'Montserrat',
    color: "red",
  },

  verifyContainer: {
    marginTop: 10,

  },

  verifyBoldText: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    marginBottom: 30,
    marginTop: 0,
    fontWeight: '500',
    letterSpacing: '0.15px'
  },

  iconTextContainer: {
    display: 'flex',
  },

  needHelp: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1em',
    marginLeft: 10,
    fontWeight: '600',
  },

  loaderContainer: {
    width: '100%',
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  successMsg: {
    color: "green",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: 'bold'
  },

  iconStyle: {
    width: 20,
    height: 20,
    marginRight: 5,
  },

  orContainer: {
    width: '80%',
    margin: '0 auto',
    borderTop: 'solid 1px #979797',
    position: 'relative',
    marginBottom: 20,
  },

  orWraper: {
    width: 30,
    height: 30,
    position: 'absolute',
    top: -16,
    left: '45%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  orText: {
    color: "#727272",
    fontFamily: "Montserrat",
    fontSize: "1.4em",
    fontWeight: "500",
    margin: 0,
  },

  saveBtn: {
    width: 192,
    height: 40,
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "600",
    letterSpacing: "0.53px",
    lineHeight: "19px",
    textAlign: "center",
  },
 
  sendBtn: {
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '600',
    marginBottom: 20,
    color: '#535D6D !important',
    border: 'solid 2px',
    borderColor: theme.palette.primary.main,
    boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.14)",
    height: 50,
  },
   
  submitBtn: {
    marginBottom: 20,
    fontSize: 16,
    fontWeight: '600',
  },

   contactUs: {
    color: '#007FFF',
    cursor: "pointer"
  },
}));
